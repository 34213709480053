// Plato Variables

$brand-color-01: #286EDC;
$brand-color-02: #002846;
$brand-color-03: #E67E70;
$brand-color-04: #f5dc4b;
$brand-color-05: #2d9682;
$light-grey: #F3F0F0;
$black: #000000;
$white: #ffffff;
$red: #fe3902;
$orange: #F4B40D;
$green: #04B42B;