@import "_brand-ripple";
//@import "_brand-gxo";
//@import "_brand-natwest";
//@import "_brand-mitie";
//@import "_brand-volkerwessels";

// Plato Breakpoints

@mixin breakpoint($point) {
    @if $point == desktop {
        @media (min-width: 1600px) { @content ; }
    }
    @else if $point == laptop {
        @media (min-width: 1300px) { @content ; }
    }
    @else if $point == tablet {
        @media (min-width: 768px) { @content ; }
    }
    @else if $point == phablet {
        @media (min-width: 480px)  { @content ; }
    }
    @else if $point == mobileonly {
        @media (max-width: 480px)  { @content ; }
    }
    @else if $point == mobileold {
        @media (max-width: 320px)  { @content ; }
    }
    @else if $point == custom {
        @media (max-height: 630px)  { @content ; }
    }
    @else if $point == custom-02 {
        @media (min-width: 1300px) { @content ; }
    }
    @else if $point == custom-03 {
        @media (max-width: 1300px) { @content ; }
    }
 }

 // Generic
 body{
    background-color: $light-grey !important;
 }

 // Font Colors

 .color-01{
    color: $brand-color-01 !important;
 }

 .color-02{
    color: $brand-color-02 !important;
 }

 .color-03{
    color: $brand-color-03 !important;
 }

 .color-04{
    color: $brand-color-04 !important;
 }

 .color-05{
    color: $brand-color-05 !important;
 }

 .color-red{
    color: $red;
 }

 .color-orange{
    color: $orange;
 }

 .color-green{
    color: $green;
 }

 // Display

 .display-none{
    display: none !important;
 }

 .flex-space-between{
    display: flex;
    justify-content: space-between;
 }

 .flex-wrap{
    display: flex;
    flex-wrap: wrap !important;
 }

 .flex-no-wrap{
    display: flex;
    flex-wrap:nowrap !important;
 }
 
 .flex-all-center{
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .flex-push-right{
    margin-left: auto;
 }

 // Font Sizes

 .ic-font-size{
    font-size: 1.2rem;
 }

 .ic-font-size-mid-01{
    font-size: 1.4rem;
 }

 .ic-font-size-lg-01{
    font-size: 1.6rem;
 }

.ic-full-height{    
    @include breakpoint(tablet){
        display: block;
        height: 100%;
    }
}

// spaces
.ic-rm-01{
    margin-right: 1rem;
}

// Buttons 

 .big-button, .medium-button{
    background-color: $brand-color-03;
    border-radius: 0.5rem;
    color: $white;
    text-align: center;
    font-size: 1.5rem;
    padding: 0.8rem;
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    //align-items: center;
    justify-content: center;
    transition: 0.5s all ease-in !important;
    &--grey{
       background-color: rgba($black, 0.05); 
       color: $brand-color-02;
       font-size: 1.2rem;
       i{
        color: $brand-color-01;
        font-size: 1.2rem !important;
        transition: 0.5s all ease-in;
       }
       &:hover{
            i{
                color: $white !important;
            }
       }
    }
    &:hover{
        background-color: $brand-color-01;
        color: $white;        
    }
    img{
        height: 1.9rem;
        margin: 0 0.5rem;
    }
    i{
        font-size: 1.8rem;
        color: $white;
        margin-right: 0.5rem;
    }
 }

 .medium-button{
    position: relative;
    top: -0.6rem;
    padding: 0.6rem 1rem;
    font-size: 1.2rem;
    img{
        height: 1.7rem;
        margin: 0 0.5rem;
    }
    i{
        font-size: 1.4rem;
    }
    &--color-01{
        background-color: $brand-color-01;
        &:hover{
            background-color: $brand-color-03;
        }
    }
 }

button.small {
    font-size: 9px;
    width: 55px;
}

.nobg-button{
    background-color: rgba($white, 0);
    width: 100%;
    color: $brand-color-02;
    font-size: 1rem;
}

 .ql-container{
    height: 12rem !important;
 }

 .no-label{
    .form-label{
        display: none;
    }
 }

 .ic-btn-icon-small{
    width: 30px !important;
    height: 30px !important;
 }

 // Accordian

 .accordion-item{
    background-color: rgba($white, 0);
 }

.accordion-button{
    padding: 1rem !important;
    margin: 0 !important;
    font-size: 1.5rem;
    background-color: rgba($white, 0.8) !important;
    color: $brand-color-02 !important;
    &.collapsed{
        color: $brand-color-01 !important;
        background-color: rgba($white, 0.5) !important;
    }
 }

 .accordion-body{
    padding: 0 !important;
    margin: 0 !important;
 }

 .small-input{
    width: 95%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    border: 1px solid $gray-300;
}

// Modal

.modal-header{
    .modal-title{
        text-align: center;
        width: 100%;
    }    
}

.moods-modal-content{
    .form-label{
        display: none !important;    }
    .ic-widget-small__form-list__row__mood{
        width: 100%;
        justify-content: center !important;
        a{
            margin: 0 auto;
        }
    }    
}

.modal-body{
    .ic-widget-small__form-list__row__mood{
        text-align: center;
        .ic-widget-small__form-list__row__mood__item-m{
            @include breakpoint(laptop){
                margin: 0.5rem 2rem 0.5rem 2rem;
            }
        }
        
    }
}

// Table

.table-striped{
    font-size: 1.2rem;
    > tbody{
        > tr:nth-of-type(2n+1){
            background-color: rgba($black, 0.1) !important;
            --bs-table-accent-bg: rgba($black, 0) !important;
            border-radius: 0.5rem !important;
        }
        td{
            padding: 0.5rem !important;
        }
    } 
} 

// Menu
.aside{   
    &.aside-dark{
        background-color: $brand-color-01 !important;
        .aside-logo{
            background-color: $brand-color-01 !important;
            @include breakpoint(custom-03){
                display: none !important;
            }
        }
    }
    .aside-menu{
        .menu{
            .menu-item{
                margin: 0 0 0 0.6rem !important;
                padding: 0 !important;
                border-bottom: 1px solid rgba($white, 0.1) !important;
                .menu-link{
                    padding: 1rem;
                    font-size: 0.8rem;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    &:hover{
                        background-color: rgba($black, 0.1);
                    }
                    &.active{
                        font-weight: 600;
                        background-color: rgba($black, 0.1) !important;
                    }
                }
            } 
        } 
        #kt_aside_menu_wrapper{
            margin: 0.5rem 0;
        }
    }     
}

.asideresponsive {
    margin: 1.5rem 2rem;
    @include breakpoint(custom-02){
        display: none !important;
    }
}

// Paging

.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    text-align: center;
    font-size: 1.2rem;
    &__active, a{
        padding: 0.5rem 1rem;
        margin: 0.5rem;
        background-color: $brand-color-03;
        color: $white;
        border-radius: 0.2rem;
    }
    li{
        &:nth-last-child(-n+2), &:nth-child(-n+3) {
            a{
                border-radius: 0.2rem;  
            }
        } 
    }
    a{
        background-color: rgba($black, 0.02);
        color: $brand-color-01;
        &:hover{
            background-color: $brand-color-02;
            color: $white;
        }
    }
}


// Login Page

.login-background{
    background-image: url(../../../../../public/media/patterns/login-background.png);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 80vw;
    @include breakpoint(tablet){
        background-size: 50vw;
    }
    @include breakpoint(laptop){
        background-size: 30vw;
    }
    h1{
        font-size: 2rem;
        color: $brand-color-02;
        font-weight: 400;
        span{
            color: $brand-color-01;
            font-weight: 500;
        }
    }
}

// Widget Style

.ic-widget-cont{
    background-color: $white !important;
    border:0px solid $white;
    padding: 1rem;
    .card-header{
        padding: 0 !important;
        .btn-icon{
            width: 30px !important;
            height: 30px !important;
        }
        .btn-danger{
            background-color: $red !important;
        }
    }
    .card-body{
        padding: 1rem 0;
    }
    &--r-spacer{        
        @include breakpoint(laptop){
            margin-right: 1rem !important;
        }
    }
    &__stats{
        display: flex; 
        flex-wrap: wrap;
        margin: 1rem 0;
        padding: 0;        
        list-style: none;        
        li{
            list-style: none;
            width: calc(100% - 1rem);
            padding: 1.5rem 1rem;
            border-radius: 1rem;
            background-color: rgba($black, 0.05);
            margin: 0 0 0.5rem;
            text-align: center;
            &.brand-color-4{
                background-color: $brand-color-04;
            }
            &.brand-color-5{
                background-color: $brand-color-05;
            }
            @include breakpoint(tablet){
                width: calc(50% - 1rem);
                margin: 0.5rem;
            }
            i{
                font-size: 2.8rem;
            }
            img{
                width: 35px;
            }
            h3{
                font-weight: 400;
                color: $brand-color-02;
                margin: 0.5rem 0;
                font-size: 1.4rem;
            }
            h2{
                font-weight: 800;
                color: $brand-color-02; 
                margin: 0.5rem 0;
                font-size: 2.7rem;
            }
        }
        &--b{
            margin: 1rem 0 2rem;
            li{
                padding: 2rem 1rem !important;
            }
            i{
                font-size: 3.5rem;
                margin-right: 1rem;
            }
            h2{
                font-size: 3.5rem;
            }
            h3{
                font-size: 1.6rem;
            }
        }
        &--4-cols{            
            li{
                @include breakpoint(laptop){
                    width: calc(25% - 1rem) !important;
                }
            }            
        }
        &--3-cols{            
            li{
                @include breakpoint(laptop){
                    width: calc(33.333% - 1rem) !important;
                }
            }            
        }
    }
    &__sub-content{
        padding: 1.5rem;
        background-color: rgba($black, 0.04);
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        h3{
            color: $brand-color-01;
            font-size: 1.7rem;
            font-weight: 400;
            margin-bottom: 1rem;
        }
        .fas{
            color: $brand-color-03;
            padding: 0.5rem;
        }
    }
    h1, h2, h3{
        span{
            font-weight: 300;
        }
    }
    h1{
        i{
            font-size: 1.8rem;
            margin:0 0.5rem 0 0;
        }
    }
}

.ic-widget-small{
    background-color: rgba($black, 0.05) !important;
    border: 0px solid $white;
    border-radius: 0.5rem !important;
    padding: 0.5rem;
    margin: 0.5rem !important;
    flex-shrink: 0 !important;
    display: block;
    height: 100%;   
    &--alert{
        background-color: rgba($green, 0.1) !important;
    } 
    &__row{
        display: flex;
        padding: 0 0 0.5rem;
        &--title{
            border-bottom: 1px solid rgba($black, 0.05);
            margin-bottom: 1rem;
            align-items: center;
            .user-name{
                height: auto;
                list-style: 100%;
            }
            .user-role-remove{
                background-color: $red !important;
                width: 25px !important;
                height: 25px !important;
            }
            i{
                color: $brand-color-01;
                margin-right: 0.5rem;
            }
        }      
    }  
    &__form-list{
        &__row{
            margin: 1rem 0;
            &__select-list{
                display: flex;
                flex-direction: row;
                align-items: stretch;                
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                list-style: none;
                &__item{
                    margin: 0.5rem 0.2rem 0.5rem 0;
                    flex: 1;
                    a, button{
                        background-color: rgba($black, 0.05);
                        border-radius: 1rem;
                        display: block;
                        padding: 1rem;
                        text-align: center;
                        color: $brand-color-02;
                        transition: 0.5s all ease;
                        border: none;
                        width:100%;
                        cursor: pointer;
                        &.active, &:hover{
                            background-color: $brand-color-03 !important;
                            color: $white;
                        }
                    }
                }
            }
            &__mood{
                // display: flex;
                // justify-content: space-between;
                // flex-wrap: wrap;
                margin: 0;
                padding: 0;
                // @include breakpoint(laptop){
                //     width: 75%;
                // }
                // @include breakpoint(desktop){
                //     width: 50%;
                // }
                &__item-m{
                    margin: 0.5rem 1rem 0.5rem 0;
                    flex: 1;
                    width:40px;
                    height: 40px;
                    color: rgba($white, 0);
                    display: inline-block;
                    @include breakpoint(tablet){
                        margin: 0.5rem 2rem 0.5rem 0;
                    }
                    a, button{
                        width: 40px;
                        height: 40px;
                        display: block;
                        //text-indent: -9999999rem;
                        font-size: 0.01rem;
                        color: rgba($white, 0);
                        background-size: 40px 40px;
                        background-color: rgba($white,0);
                        cursor: pointer;
                        opacity: 1;
                        border: none;
                        &.angry{
                            background-image: url('../../../../../public/media/moods/mood-angry-regular-active.png');
                        }
                        &.frown{
                            background-image: url('../../../../../public/media/moods/mood-frown-regular-active.png');
                        }
                        &.meh{
                            background-image: url('../../../../../public/media/moods/mood-meh-regular-active.png');
                        }
                        &.smile{
                            background-image: url('../../../../../public/media/moods/mood-smile-regular-active.png');
                        }
                        &.laugh{
                            background-image: url('../../../../../public/media/moods/mood-laugh-regular-active.png');
                        }
                        &.deactivate{
                            opacity: 0.35;
                        }
                    }
                }
            }
        }
    }
    &__recommendation{
        background-color: rgba($black, 0.02);
        padding: 1rem;
        margin: 0.5rem 0;
        border-radius: 0.5rem;
        ul{
            margin: 1rem 0;
            padding: 0;
            list-style: none;
            li{
                margin: 0 0 0.5rem;   
                h3{
                    color: $brand-color-03;
                    font-weight: 400;
                    font-size: 1.3rem;
                }
                p{
                    color: $body-color;
                }
                a{
                    display: block;
                    &:hover{
                        h3{
                            color: $brand-color-01;
                        }                        
                    }
                }
            }
        }        
    }
    h1, h2, h3{
        span{
            font-weight: 300;
        }
    }
    h1{
        margin: 0 0 1rem;
        i{
            font-size: 1.8rem;
            margin-right: 0.5rem;
        }
    }
    h2{
        color: $brand-color-01;
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    h3{
        color: $brand-color-01;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    ol{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            margin: 0 0 1rem;
            input[type='checkbox']{
                margin: 0 0.5rem 0 0;
            }
        }
    }
    textarea{
        width: 100%;
        height: 15rem;
        border-radius: 0.5rem;
        border: 1px solid $gray-300;
    }
    .form-control-select{
        padding: 0;
    }
    .card-body{
        padding: 1rem;
    }    
    .card-footer{
        background-color: rgba($black, 0) !important;
    }  
    .btn-icon{
        width: 30px !important;
        height: 30px !important;
    }
}

.ic-widget-button{
    background-color: rgba($black, 0.05) !important;
    border: 0px solid $white;
    padding: 0.5rem 0.5rem 3rem 0.5rem ;
    margin: 0.5rem !important;
    color: $brand-color-01;
    display: block;
    height: 100%;     
    h1{
        color: $brand-color-02;
        font-size: 1.7rem;
        font-weight: 400;
        text-align: center;
        margin: 1rem 0 0;
    }
    .btn-primary{
        background-color: rgba($brand-color-05, 1) !important;
        color: $white;
        width: auto;
        font-size: 1rem;
        margin: 0 auto;
        i{
            color: $white;
            display: none;
        }
        &:hover{
            background-color: rgba($brand-color-02, 0.8) !important;
        }
    }
    .card-footer{
        background-color: rgba($black, 0) !important;
        border-top: 0 solid $brand-color-01;
        position: absolute;
        bottom: 0.5rem;
        width: calc(100% - 1rem);
    }  
}

// Responsive for card options
.ic-small-widget-wrapper, .ic-widget-cont-wrapper{
    width:100% !important;
    margin-bottom: 0.5rem;
    @include breakpoint(tablet){
        width: 50% !important;
    }
    @include breakpoint(tablet){
        width: 33.333% !important;
    }
    @include breakpoint(laptop){
        width: 33.333% !important;
    }
    &--4-col-desk{
        @include breakpoint(tablet){
            width: 50% !important;
        }
        @include breakpoint(laptop){
            width: 25% !important;
        }
    }
    &--3-col-desk{
        @include breakpoint(tablet){
            width: 50% !important;
        }
        @include breakpoint(laptop){
            width: 33.333% !important;
        }
    }
    &--2-col-desk{
        @include breakpoint(tablet){
            width: 100% !important;
        }
        @include breakpoint(laptop){
            width: 50% !important;
        }
    }
    &--1-col-desk{
        width: 100% !important;
    }    
    &--75-col-desk{
        @include breakpoint(laptop){
            width: 75% !important;
        }
    }
}

// Navigation
.nav-next-pre{
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin: 0.5rem 0 1rem;
    padding: 0 0.5rem;
    width: 100%;
    li{
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: $brand-color-02;
        &:hover{
            color: $brand-color-01;
        }
        i{
            color: $brand-color-01;
            padding: 0 0.5rem;
        }
        &.disabled{
            color: rgba($black, 0.2);
            cursor: default;
            i{
                color: rgba($black, 0.2);
            }
        }
        span{
            display: none;
            @include breakpoint(laptop){
                display: inline;
            }
        }
    }
}

// Cause / Impact section List

.causeSelectionList{
    div{
        margin: 0.6rem 0;
    }
}

// Table
.ic-table{
    width: 100%;
    margin: 1rem 0;
    padding: 0.2rem;
    background-color: rgba($black, 0.05);
    border-radius: 0.5rem;
    list-style: none;
    &__row{
        border-radius: 0.5rem;
        margin: 0.2rem 0;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
        @include breakpoint(laptop){
            display: flex; 
            padding: 0;
        }
        &__link{
            @include breakpoint(laptop){
                display: flex; 
                padding: 0;
                color: $body-color;
            }
            
        }
        &--button{
            background-color: rgba($white, 0);
        }
        &--header{
            font-weight: bold;
            background-color: $brand-color-01;
            color: $white !important;            
            font-size: 1.2rem;
            font-weight: 400;
            border-radius: 0.5rem;  
            display: none;
            @include breakpoint(laptop){
                display: flex;
            } 
            &--sticky{
                position: sticky;
                z-index: 9;
                top: calc(82px - 1rem);
                background-color: rgba($black, 0.2);
            }            
        }
        &__field{
            padding: 0.5rem;
            &--smallest{
                @include breakpoint(laptop){
                    flex:0.25;
                    text-align: center;
                }                         
            }
            &--smaller{
                @include breakpoint(laptop){
                    flex:0.5;
                }                         
            }
            &--small{
                @include breakpoint(laptop){
                    flex:2;
                }                        
            }
            &--medium{
                @include breakpoint(laptop){
                    flex:3;
                }
            }
            &--large{
                @include breakpoint(laptop){
                    flex:4;
                }                        
            }
            &--larger{
                @include breakpoint(laptop){
                    flex:10;
                }                        
            }
            &--largest{
                @include breakpoint(laptop){
                    flex:16;
                }                        
            }
            &--full-width{
                @include breakpoint(laptop){
                    width:100%;
                } 
            }
            &--highlight{
                color: $brand-color-02;
                font-weight: 500;
            }
        }       
        &:nth-child(even) {
            background: rgba($white,0.8);
            @include breakpoint(laptop){
                background: rgba($white,0.5);
            }    
        }
        h2{
            margin: 0;
            padding: 0;
            color: $brand-color-02;
            font-size: 1.3rem;
            font-weight: 600;
            @include breakpoint(laptop){
                font-size: 1.2rem;
                font-weight: 500;
            }                 
        }
        .mood-icon{
            width:20px;
        }
        &__link{
            width:100%;
            display: block;
            transition: 0.3s all ease-in;
            @include breakpoint(laptop){
                display: flex;
                justify-content: space-between;
                align-items: stretch;                                           
                flex-wrap: wrap;
            }  
            &:hover{
                border-radius: 0.5rem;
                background-color: rgba($black,0.05);
            }
        }
    }
}  

// Toolbar

.toolbar{
    height: auto;
}

.eap-toolbar{
    font-size: 0.9rem;
    text-align: right;
    i{
        font-size: 1.3rem;
        margin-right: 0.5rem;
        display: none;
        @include breakpoint(tablet){
            font-size: 1.1rem;
            display: block;
        }
    }
    @include breakpoint(tablet){
        text-align: left;
        font-size: 1.1rem;
    } 
}

// Tag

.ic-tag{
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-align: center;
    background-color: rgba($black, 0.05);
    font-size: 0.8rem;
    display: block;
    white-space: nowrap;
    strong{
        font-size: 1.2rem;
    }
    &--red{
        background-color: $red;
        color: $white;
    }
    &--orange{
        background-color: $orange;
        color: $white;
    }
    &--green{
        background-color: $green;
        color: $white;
    }
}


// Old Styles

#kt_wrapper {
    background-color: $light-grey !important;    
}

#kt_footer{
    background-color: rgba($black, 0);
}

#kt_content_container{
    position: relative;
}

.toast {
    opacity: 1 !important;
}

.btn i { 
    vertical-align: unset !important;
}

.loggedUserInitials {
    background: #2d2d50;
    color: white;
    opacity: 1; 
    display: inline-block; 
    font-weight: 600; 
    border-radius: 50%; 
    vertical-align: middle; 
    margin-right: 0.5em; 
    width: 50px; 
    height: 50px; 
    line-height: 50px; 
    text-align: center; 
}

.form-control-select {
    @extend .form-control;
    border: none !important;
    padding-left: 0 !important;
}

.menu-arrow{
    color: white;
}

// ios fixes

input[type="date"]
{
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
}
  
@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */ 
}