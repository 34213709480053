//
// Menu
//

// Aside menu
.aside-menu {
    .menu {
        // Link padding x
        @include menu-link-padding-x(get($aside-config, padding-x));

        // Menu indention
        @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
    }

    // Item
    .menu-item {
        padding-top: 0.75em;
        margin-left: 1.5em !important;
        margin-right: 1.5em !important;
        border-bottom: 0.05em solid #5e96ee;

        // Menu Link
        .menu-link {
            padding: 0.75rem;
            border-top-right-radius: 1em;
            border-top-left-radius: 1em;
        }

        // Icon
        .menu-icon {
            justify-content: flex-start;
            color: $white;
            font-size: 1.25em;
        }
    }
}

// Aside dark theme
.aside-dark {
    .hover-scroll-overlay-y {
        --scrollbar-space: 0.4rem;

        @include scrollbar-color(get($aside-config, scrollbar-color), get($aside-config, scrollbar-hover-color));
    }

    .menu {
        .menu-item {
            .menu-section {
                color: #4c4e6f !important;
            }

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state($white, #2058b0, $white, #9899ac, null);
            @include menu-link-hover-state($white, $primary, $primary, $white, #1c4d9a);
            @include menu-link-here-state($white, $primary, $primary, $white, #1b1b28);
            @include menu-link-show-state($white, $primary, $primary, $white, #1b1b28);
            @include menu-link-active-state($white, $primary, $primary, $white, #3d7cdf);
        }
    }
}

// Aside light theme
.aside-light {
    .menu {
        .menu-title {
            font-weight: 400;
        }
    }
}